import HttpGlobal from "../Global/HttpGlobal.class";
import {
  BeneficiaryDataI,
  BeneficiariesAllI,
  BeneficiariesAvailableCapitalI,
  BeneficiariesBeneficiaryFormI,
  GetBeneficiaryInfoI,
} from "./Interfaces/BeneficiariesInterfaces";

export default class HttpBeneficiariesRepository extends HttpGlobal {
  private readonly API_BENEFICIARIES_URL:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/beneficiaries`;

  getTypeBeneficiaries = async (): Promise<any> => {
    const response = await this.generalGet(
      `${this.API_BENEFICIARIES_URL}/type/${this.leadId}`
    );

    return response;
  };

  saveTypeBeneficiary = async (type: string): Promise<any> => {
    const response = await this.generalPost({
      url: `${this.API_BENEFICIARIES_URL}/type/${this.leadId}`,
      dataRequest: { type: type },
    });

    return response;
  };

  setBeneficiary = async (
    form: BeneficiariesBeneficiaryFormI,
    method: "POST" | "PUT" = "POST"
  ): Promise<any> => {
    let response = null;

    if (method === "POST") {
      response = await this.generalPost({
        url: `${this.API_BENEFICIARIES_URL}/${this.leadId}`,
        dataRequest: { ...form },
      });
    }

    if (method === "PUT") {
      response = await this.generalPut({
        url: `${this.API_BENEFICIARIES_URL}/${this.leadId}`,
        dataRequest: { ...form },
      });
    }

    return response;
  };

  confirmBeneficiaries = async (accept: string): Promise<any> => {
    const response = await this.generalPost({
      url: `${this.API_BENEFICIARIES_URL}/confirm/${this.leadId}`,
      dataRequest: { acceptUserCommunications: accept },
    });

    return response;
  };

  deleteBeneficiary = async (beneficiaryId: string) => {
    const response = await this.generalDelete({
      url: `${this.API_BENEFICIARIES_URL}/${beneficiaryId}/${this.leadId}`,
    });

    return response;
  };

  getBeneficiaryInfo = async (
    beneficiaryId: string
  ): Promise<GetBeneficiaryInfoI> => {
    const response = await this.generalGet(
      `${this.API_BENEFICIARIES_URL}/info/${beneficiaryId}/${this.leadId}`
    );

    return response;
  };

  getAllResumeBeneficiaries = async (): Promise<BeneficiaryDataI[]> => {
    const response = await this.generalGet(
      `${this.API_BENEFICIARIES_URL}/resume/all/${this.leadId}`
    );
    return response;
  };

  getBeneficiariesAvailableCapital =
    async (): Promise<BeneficiariesAvailableCapitalI> => {
      const response = await this.generalGet(
        `${this.API_BENEFICIARIES_URL}/availableCapital/${this.leadId}`
      );
      return response;
    };

  getBeneficiaryAvailableCapital = async (
    beneficiaryId: string
  ): Promise<BeneficiariesAvailableCapitalI> => {
    const response = await this.generalGet(
      `${this.API_BENEFICIARIES_URL}/availableCapital/${this.leadId}/${beneficiaryId}`
    );
    return response;
  };

  getAllBeneficiaries = async (): Promise<BeneficiariesAllI> => {
    const response = await this.generalGet(
      `${this.API_BENEFICIARIES_URL}/all/${this.leadId}`
    );
    return response;
  };
}
