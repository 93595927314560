import React, { ReactNode, useContext, useEffect, useState } from "react";
import { GetlifeContext } from "./GetLifeContext.context";
import {
  GlobalCountryApi,
  GlobalProvinceApi,
} from "../utils/InternationlChanges";
import HttpLeadRepository from "../api/request/Lead/Lead.service";
import { I18nContext } from "./i18n.context";
import { useNavigate } from "react-router";
import {
  errorsTerzo,
  parseErrors,
} from "../views/BeneficiaryIT/constBeneficiaries";
import HttpBeneficiariesRepository from "../api/request/Beneficiaries/Beneficiaries.service";
import {
  BeneficiariesBeneficiaryFormI,
  DataOfBeneficiaryI,
} from "../api/request/Beneficiaries/Interfaces/BeneficiariesInterfaces";
import { ulid } from "ulid";

interface ContextProps {
  children: ReactNode;
}

interface terzoReferenteI {
  leadUlid: string;
  name: string;
  lastName: string;
  fiscalCode: string;
  gender: string;
  dateOfBirth: string;
  countryOfBirth: string;
  cityOfBirth: string;
  email: string;
  street: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  country: string;
}

interface BeneficiaryITContextI {
  isSelectBeneficiaryType: string;
  translate: (key: string) => string;
  beneficiaryType: string;
  setBeneficiaryType: (value: string) => void;
  checkedConditions: boolean;
  setCheckedConditions: (value: boolean) => void;
  showTerzo: string | null;
  setShowTerzo: (value: string | null) => void;
  terzoReferente: terzoReferenteI;
  setTerzoReferente: (value: terzoReferenteI) => void;
  cityList: any;
  countryList: any;
  saveBeneficiaryType: () => void;
  terzoError: any;
  currentBeneficiary: CurrentBeneficiaryI;
  updateCurrentBeneficiary: UpdateCurrentBeneficiaryFn;
  updateCurrentBeneficiaryCapital: ({
    capital,
    percentage,
  }: {
    capital?: number;
    percentage?: number;
  }) => void;
  beneficiariesList: DataOfBeneficiaryI[] | [];
  beneficiaryForm: BeneficiaryFormI;
  updateBeneficiaryForm: UpdateBeneficiaryFormFn;
  submitBeneficiaryForm: () => void;
  beneficiaryFormError: BeneficiaryFormErrorI;
  clearBeneficiaryError: ClearBeneficiaryErrorFn;
  confirmBeneficiaries: () => void;
  deleteBeneficiary: (beneficiaryId: string) => Promise<boolean>;
  loading: boolean;
  setLoading: (value: boolean) => void;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  editBeneficiary: (beneficiaryId: string) => void;
  acceptUserCommunications: string;
  setAcceptUserCommunications: (value: "yes" | "no") => void;
  setBeneficiaryForm: (value: BeneficiaryFormI) => void;
  isEditingCapital: boolean;
  setIsEditingCapital: (value: boolean) => void;
  frontalDocument: string;
  backDocument: string;
  optionDocument: string;
  setFrontalDocument: (image: string) => void;
  setBackDocument: (image: string) => void;
  setOptionDocument: (option: string) => void;
  updateIsDocumentEdited: (type: "FRONT" | "BACK") => void;
}

interface CurrentBeneficiaryI {
  capital?: number;
  percentage?: number;
}

interface UpdateCurrentBeneficiaryFn {
  <K extends keyof CurrentBeneficiaryI>(
    field: K,
    value: CurrentBeneficiaryI[K]
  ): void;
}

export interface BeneficiaryFormI {
  beneficiaryId?: string;
  name?: string;
  lastName?: string;
  fiscalCode?: string;
  gender?: "Male" | "Female";
  dateOfBirth?: string;
  countryOfBirth?: string;
  cityOfBirth?: string;
  email?: string;
  capitalPercentage?: number;
  residentialAddress?: {
    streetName?: string;
    streetNo?: string;
    city?: string;
    zipCode?: string;
    country?: string;
  };
  domicileSameAsResidence?: "yes" | "no";
  domicileAddress?: {
    streetName?: string;
    streetNo?: string;
    city?: string;
    zipCode?: string;
    country?: string;
  };
  affinityRelationship?: string | null;
  otherAffinityRelationship?: string;
  pepIndicator?: "yes" | "no";
  pepType?: string | null;
  pepRelation?: string | null;
}

export type BeneficiaryFormErrorI = {
  name?: string;
  lastName?: string;
  fiscalCode?: string;
  gender?: string;
  dateOfBirth?: string;
  countryOfBirth?: string;
  cityOfBirth?: string;
  email?: string;
  residentialAddress?: {
    streetName?: string;
    streetNo?: string;
    city?: string;
    zipCode?: string;
    country?: string;
  };
  domicileSameAsResidence?: string;
  domicileAddress?: {
    streetName?: string;
    streetNo?: string;
    city?: string;
    zipCode?: string;
    country?: string;
  };
  beneficiaryDocumentation?: string;
  affinityRelationship?: string;
  pepIndicator?: string;
  otherAffinityRelationship?: string;
  pepType?: string;
  pepRelation?: string;
};
interface UpdateBeneficiaryFormFn {
  <K extends keyof BeneficiaryFormI>(
    field: K,
    value: BeneficiaryFormI[K]
  ): void;
}

export const BeneficiaryITContext = React.createContext(
  {} as BeneficiaryITContextI
);

interface ClearBeneficiaryErrorFn {
  <K extends keyof BeneficiaryFormErrorI>(field: K, subfield?: string): void;
}

export const BeneficiaryITProvider = ({ children }: ContextProps) => {
  const navigate = useNavigate();

  const { token, leadId, domain } = useContext(GetlifeContext);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const [isSelectBeneficiaryType, setISelectBeneficiaryType] =
    useState<string>("");
  const [beneficiaryType, setBeneficiaryType] = useState<string>("");
  const [checkedConditions, setCheckedConditions] = useState<boolean>(false);
  const [showTerzo, setShowTerzo] = useState<string | null>(null);
  const [terzoReferente, setTerzoReferente] = useState<terzoReferenteI>({
    leadUlid: "",
    name: "",
    lastName: "",
    fiscalCode: "",
    gender: "",
    dateOfBirth: "",
    countryOfBirth: "",
    cityOfBirth: "",
    email: "",
    street: "",
    streetNumber: "",
    city: "",
    zipCode: "",
    country: "IT",
  });
  const [terzoError, setTerzoError] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [currentBeneficiary, setCurrentBeneficiary] =
    useState<CurrentBeneficiaryI>({});
  const [beneficiariesList, setBeneficiariesList] = useState<
    DataOfBeneficiaryI[] | []
  >([]);
  const [beneficiaryForm, setBeneficiaryForm] = useState<BeneficiaryFormI>({});
  const [beneficiaryFormError, setBeneficiaryFormError] =
    useState<BeneficiaryFormErrorI>({});
  const [acceptUserCommunications, setAcceptUserCommunications] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditingCapital, setIsEditingCapital] = useState(false);
  const [frontalDocument, setFrontalDocument] = useState("");
  const [backDocument, setBackDocument] = useState("");
  const [optionDocument, setOptionDocument] = useState("");
  const [isDocumentEdited, setIsDocumentEdited] = useState({
    FRONT: false,
    BACK: false,
  });
  const [leadCodiceFiscale, setLeadCodiceFiscale] = useState("");

  const leadRepository = new HttpLeadRepository(leadId, token);
  const beneficiariesRepository = new HttpBeneficiariesRepository(
    leadId,
    token
  );

  React.useEffect(() => {
    leadRepository
      .getQuestionNomenclator(GlobalCountryApi[domain])
      .then((res) => {
        const { answers } = res;

        setCountryList(answers);
      });

    leadRepository
      .getQuestionNomenclator(GlobalProvinceApi[domain])
      .then((res) => {
        const { answers } = res;

        setCityList(answers);
      });
  }, [leadId, token]);

  const saveBeneficiaryType = async () => {
    if (showTerzo === "yes") {
      // Limpiar Errores
      setTerzoError({});
      terzoReferente.leadUlid = leadId;
      await leadRepository
        .saveTerzoReferente(terzoReferente)
        .then(async (response) => {
          await beneficiariesRepository
            .saveTypeBeneficiary(beneficiaryType)
            .then(() => {
              if (beneficiaryType === "Free designation") {
                // Activar formulario de datos
                setISelectBeneficiaryType("Free designation");
              } else {
                navigate("/coverage/7");
              }
            });
          return response;
        })
        .catch((e) => {
          if (e.message) {
            let key = parseErrors[e.message as errorsTerzo];
            setTerzoError({
              [key]: { error: true, message: translate(`terzoError.${key}`) },
            });
          }
          return e;
        });
    } else {
      const data = {
        beneficiaries: beneficiaryType,
      };
      await beneficiariesRepository
        .saveTypeBeneficiary(beneficiaryType)
        .then(() => {
          if (beneficiaryType === "Free designation") {
            // Activar formulario de datos
            setISelectBeneficiaryType("Free designation");
          } else {
            navigate("/coverage/7");
          }
        });
    }
  };

  const updateCurrentBeneficiary: UpdateCurrentBeneficiaryFn = (
    field,
    value
  ) => {
    const _currentBeneficiary = { ...currentBeneficiary };
    _currentBeneficiary[field] = value;
    setCurrentBeneficiary(_currentBeneficiary);
  };

  const updateCurrentBeneficiaryCapital = ({
    capital,
    percentage,
  }: {
    capital?: number;
    percentage?: number;
  }) => {
    const _currentBeneficiary = { ...currentBeneficiary };
    _currentBeneficiary.capital = capital;
    _currentBeneficiary.percentage = percentage;
    setCurrentBeneficiary(_currentBeneficiary);
  };

  const getBeneficiariesList = async () => {
    const response = await beneficiariesRepository.getAllBeneficiaries();
    setBeneficiariesList(response.beneficiaries);
  };

  React.useEffect(() => {
    (async () => {
      const beneficiaryType =
        await beneficiariesRepository.getTypeBeneficiaries();
      setISelectBeneficiaryType(
        typeof beneficiaryType === "object" ? "" : beneficiaryType
      );
      if (beneficiaryType === "Free designation") {
        getBeneficiariesList();
      }
    })();
  }, []);

  const updateBeneficiaryForm: UpdateBeneficiaryFormFn = (field, value) => {
    const _beneficiaryForm = { ...beneficiaryForm };
    _beneficiaryForm[field] = value;
    if (field === "countryOfBirth") {
      _beneficiaryForm["cityOfBirth"] = "";
    }
    setBeneficiaryForm(_beneficiaryForm);
  };

  const submitBeneficiaryForm = async () => {
    setLoading(true);
    const errors = validateBeneficiaryForm();
    if (Object.keys(errors).length === 0) {
      const form = {
        beneficiaryId: beneficiaryForm.beneficiaryId ?? ulid(),
        capitalPercentage:
          beneficiaryForm.capitalPercentage ?? currentBeneficiary.percentage,
        ...beneficiaryForm,
        otherAffinityRelationship:
          beneficiaryForm.affinityRelationship === "Altro"
            ? beneficiaryForm.otherAffinityRelationship
            : null,
        beneficiaryDocumentation: {
          documentationType: optionDocument,
          documents: [
            ...(frontalDocument
              ? [
                  {
                    type: "FRONT",
                    base64Content: isDocumentEdited.FRONT
                      ? frontalDocument
                      : "",
                  },
                ]
              : []),
            ...(backDocument
              ? [
                  {
                    type: "BACK",
                    base64Content: isDocumentEdited.BACK ? backDocument : "",
                  },
                ]
              : []),
          ],
        },
      } as BeneficiariesBeneficiaryFormI;

      if (beneficiaryForm.domicileSameAsResidence === "yes") {
        form.domicileAddress = undefined;
      }

      await beneficiariesRepository
        .setBeneficiary(form, beneficiaryForm.beneficiaryId ? "PUT" : "POST")
        .then((r) => {
          getBeneficiariesList();
          setBeneficiaryForm({});
          setIsEditMode(false);
        })
        .catch((e) => {
          const { message } = e;
          if (message === "fiscalCode") {
            setBeneficiaryFormError({
              fiscalCode: translate("beneficiaries.it.error.fiscalCode"),
            });
          }
          if (message === "zipcodeResidential") {
            setBeneficiaryFormError({
              residentialAddress: {
                zipCode: translate("beneficiaries.it.error.zipcode"),
              },
            });
          }
          if (message === "zipcodeDomicile") {
            setBeneficiaryFormError({
              domicileAddress: {
                zipCode: translate("beneficiaries.it.error.zipcode"),
              },
            });
          }
        });
    }
    setLoading(false);
  };

  const validateBeneficiaryForm = (): BeneficiaryFormErrorI => {
    const errors: BeneficiaryFormErrorI = {};

    if (!beneficiaryForm.name) {
      errors.name = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.lastName) {
      errors.lastName = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.fiscalCode) {
      errors.fiscalCode = translate("beneficiaries.it.error.required");
    }

    if (
      beneficiaryForm.fiscalCode &&
      beneficiaryForm.fiscalCode === leadCodiceFiscale
    ) {
      errors.fiscalCode = translate("beneficiaries.it.error.sameCodiceFiscale");
    }

    if (!beneficiaryForm.gender) {
      errors.gender = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.dateOfBirth) {
      errors.dateOfBirth = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.countryOfBirth) {
      errors.countryOfBirth = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.cityOfBirth) {
      errors.cityOfBirth = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.email) {
      errors.email = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.name) {
      errors.name = translate("beneficiaries.it.error.required");
    }

    if (!beneficiaryForm.residentialAddress?.streetName) {
      errors.residentialAddress = {
        ...errors.residentialAddress,
        streetName: translate("beneficiaries.it.error.required"),
      };
    }

    if (!beneficiaryForm.residentialAddress?.streetNo) {
      errors.residentialAddress = {
        ...errors.residentialAddress,
        streetNo: translate("beneficiaries.it.error.required"),
      };
    }

    if (!beneficiaryForm.residentialAddress?.city) {
      errors.residentialAddress = {
        ...errors.residentialAddress,
        city: translate("beneficiaries.it.error.required"),
      };
    }

    if (!beneficiaryForm.residentialAddress?.zipCode) {
      errors.residentialAddress = {
        ...errors.residentialAddress,
        zipCode: translate("beneficiaries.it.error.required"),
      };
    }

    if (!beneficiaryForm.residentialAddress?.country) {
      errors.residentialAddress = {
        ...errors.residentialAddress,
        country: translate("beneficiaries.it.error.required"),
      };
    }

    if (!beneficiaryForm.domicileSameAsResidence) {
      errors.domicileSameAsResidence = translate(
        "beneficiaries.it.error.required"
      );
    }

    if (beneficiaryForm.domicileSameAsResidence === "no") {
      if (!beneficiaryForm.domicileAddress?.streetName) {
        errors.domicileAddress = {
          ...errors.domicileAddress,
          streetName: translate("beneficiaries.it.error.required"),
        };
      }

      if (!beneficiaryForm.domicileAddress?.streetNo) {
        errors.domicileAddress = {
          ...errors.domicileAddress,
          streetNo: translate("beneficiaries.it.error.required"),
        };
      }

      if (!beneficiaryForm.domicileAddress?.city) {
        errors.domicileAddress = {
          ...errors.domicileAddress,
          city: translate("beneficiaries.it.error.required"),
        };
      }

      if (!beneficiaryForm.domicileAddress?.zipCode) {
        errors.domicileAddress = {
          ...errors.domicileAddress,
          zipCode: translate("beneficiaries.it.error.required"),
        };
      }

      if (!beneficiaryForm.domicileAddress?.country) {
        errors.domicileAddress = {
          ...errors.domicileAddress,
          country: translate("beneficiaries.it.error.required"),
        };
      }
    }
    if (beneficiaryForm.affinityRelationship === "Altro") {
      if (
        !frontalDocument ||
        !optionDocument ||
        (optionDocument !== "PA" && !backDocument)
      ) {
        errors.beneficiaryDocumentation = translate(
          "beneficiaries.it.error.required"
        );
      }
      if (!beneficiaryForm.otherAffinityRelationship) {
        errors.otherAffinityRelationship = translate(
          "beneficiaries.it.error.required"
        );
      }
    }

    if (!beneficiaryForm.affinityRelationship) {
      errors.affinityRelationship = translate(
        "beneficiaries.it.error.required"
      );
    }

    if (!beneficiaryForm.pepIndicator) {
      errors.pepIndicator = translate("beneficiaries.it.error.required");
    }

    if (beneficiaryForm.pepIndicator === "yes") {
      if (!beneficiaryForm.pepType) {
        errors.pepType = translate("beneficiaries.it.error.required");
      }
      if (!beneficiaryForm.pepRelation) {
        errors.pepRelation = translate("beneficiaries.it.error.required");
      }
    }

    setBeneficiaryFormError(errors);
    return errors;
  };

  const clearBeneficiaryError: ClearBeneficiaryErrorFn = (field, subfield) => {
    const _beneficiaryFormError = { ...beneficiaryFormError };
    if (subfield) {
      if (_beneficiaryFormError[field]) {
        // @ts-ignore
        _beneficiaryFormError[field][subfield] = undefined;
      }
    } else {
      _beneficiaryFormError[field] = undefined;
    }
    setBeneficiaryFormError(_beneficiaryFormError);
  };

  const confirmBeneficiaries = async () => {
    await beneficiariesRepository
      .confirmBeneficiaries(acceptUserCommunications)
      .then(() => {
        navigate("/coverage/7");
      });
  };

  const deleteBeneficiary = async (beneficiaryId: string): Promise<boolean> => {
    let isDeleted = false;
    setLoading(true);
    await beneficiariesRepository
      .deleteBeneficiary(beneficiaryId)
      .then(async () => {
        await getBeneficiariesList();
        isDeleted = true;
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => setLoading(false));
    return isDeleted;
  };

  const editBeneficiary = async (beneficiaryId: string) => {
    setLoading(true);
    const response = await beneficiariesRepository.getBeneficiaryInfo(
      beneficiaryId
    );
    setBeneficiaryForm(response as unknown as BeneficiaryFormI);
    if (response.beneficiaryDocumentation) {
      setOptionDocument(response.beneficiaryDocumentation?.documentationType);
      const _frontDocument = response.beneficiaryDocumentation?.documents.find(
        (d) => d.type === "FRONT"
      );
      if (_frontDocument) {
        setFrontalDocument(_frontDocument.url);
      }
      const _backDocument = response.beneficiaryDocumentation?.documents.find(
        (d) => d.type === "FRONT"
      );
      if (_backDocument) {
        setBackDocument(_backDocument.url);
      }
    }
    const beneficiary = beneficiariesList.find((b) => b.id === beneficiaryId);
    setCurrentBeneficiary({
      capital: beneficiary?.capital,
      percentage: beneficiary?.percentage,
    });
    setIsEditMode(true);
    setLoading(false);
  };

  useEffect(() => {
    if (isEditMode === false) {
      setCurrentBeneficiary({});
      setBackDocument("");
      setFrontalDocument("");
      setOptionDocument("");
      setIsDocumentEdited({
        FRONT: false,
        BACK: false,
      });
    }
  }, [isEditMode]);

  const updateIsDocumentEdited = (type: "FRONT" | "BACK") => {
    setIsDocumentEdited({ ...isDocumentEdited, [type]: true });
  };

  useEffect(() => {
    leadRepository.getCheckoutData().then((res) => {
      setLeadCodiceFiscale(res?.lead?.idNumber);
    });
  }, []);

  const values = {
    isSelectBeneficiaryType,
    translate,
    beneficiaryType,
    setBeneficiaryType,
    checkedConditions,
    setCheckedConditions,
    showTerzo,
    setShowTerzo,
    terzoReferente,
    setTerzoReferente,
    cityList,
    countryList,
    saveBeneficiaryType,
    terzoError,
    currentBeneficiary,
    updateCurrentBeneficiary,
    updateCurrentBeneficiaryCapital,
    beneficiariesList,
    beneficiaryForm,
    updateBeneficiaryForm,
    submitBeneficiaryForm,
    beneficiaryFormError,
    clearBeneficiaryError,
    confirmBeneficiaries,
    deleteBeneficiary,
    loading,
    setLoading,
    isEditMode,
    setIsEditMode,
    editBeneficiary,
    acceptUserCommunications,
    setAcceptUserCommunications,
    setBeneficiaryForm,
    isEditingCapital,
    setIsEditingCapital,
    frontalDocument,
    backDocument,
    optionDocument,
    setFrontalDocument,
    setBackDocument,
    setOptionDocument,
    updateIsDocumentEdited,
  };
  return (
    <BeneficiaryITContext.Provider value={values}>
      {children as unknown as ReactNode}
    </BeneficiaryITContext.Provider>
  );
};
