import {
  CTA,
  GlobalIcon,
  Faq,
  InformationComponent,
  BoxInformation,
  RenderTrustpilot,
  TableInfo,
  Typography,
  FormCustom,
  CrossedOutElement,
  ButtonDS,
} from "get-life-storybook-ts";
import "./Rastreator.scss";
import { IconNameT } from "get-life-storybook-ts/lib/components/Icons/GlobalIcon";
import { Truspilot } from "../../utils/dataForNewUpsell";
import RastreatorController from "./RastreatorController";
import {
  CAPITAL_MODAL_STYLES,
  COVER_MODAL_STYLES,
  accordionData,
  arrayForm,
  buttonForm,
  dataTable,
  faqAccordionData,
  headerTable,
  infoItems,
  translations,
} from "./constants";
import numberFormat from "../../utils/Utils";

//TODO - add correct type on storybook

interface RastreatorContainerProps {}

export const NavbarStickyContent = ({ discountPrice, originalPrice }: any) => (
  <div className="navbarSticky buttonShadow">
    <div className="navbarSticky__content">
      <CrossedOutElement
        data={numberFormat(originalPrice)}
        className="!text-[#6F6F6F]"
      />

      <Typography
        variant="H2"
        text={numberFormat(discountPrice)}
        color="purple"
        weight="700"
      />
    </div>
  </div>
);

//TODO: Remove and reuse our FormButton component here
export const NavbarStikyButton = ({ label, onClick }: any) => (
  <div className="navbarSticky__button">
    <ButtonDS rightIcon="ArrowGoRightIcon" label={label} onClick={onClick} />
  </div>
);

const RastreatorContainer: React.FC<RastreatorContainerProps> = () => {
  const {
    dataAggregator,
    initialValues,
    isMobile,
    sendData,
    userDataSended,
    navigateToQuestions,
    translate,
  } = RastreatorController();

  return (
    <div className="rastreatorPage">
      <div className="boxInformationContainer">
        <div className="headerContainer">
          <Typography
            text={translate("rastreator.title")}
            variant="H1"
            color="life-blue-100"
            weight="700"
            className="text-center"
          />
          <Typography
            text={translate("rastreator.subtitle")}
            variant="H3"
            color="life-blue-100"
            className="flex flex-row gap-2"
            weight="500"
          >
            <GlobalIcon iconName="RastreatorIcon" />
          </Typography>
        </div>
        <div className="boxInformationContainer__body">
          <div className="boxInformationContainer__boxInformation">
            <BoxInformation
              headerProps={{
                price: {
                  original: numberFormat(dataAggregator?.originalPrice),
                  discounted: numberFormat(dataAggregator?.yearlyPrice),
                  discountPercentage: dataAggregator?.promoDescription,
                },
                discountedPriceLabel: translate("rastreator.year"),
                showDetailsLabel: translate("rastreator.seeDetails"),
                hideDetailLabel: translate("rastreator.hideDetails"),
                icon: "LogoLife5Blue" as IconNameT,
                expanded: false,
                title: translate("rastreator.boxInfo.title"),
                isMobile,
              }}
              accordion={accordionData({
                translate,
                capital: dataAggregator?.capital,
                coverage: dataAggregator?.coverage,
              })}
              capitalModalProps={CAPITAL_MODAL_STYLES}
              coverModalProps={COVER_MODAL_STYLES}
              translations={translations(translate)}
              globalRightIconProps={{
                displayedItemIcon: "ArrowDownIcon",
                closedItemIcon: "ArrowUpIcon",
                color: "var(--theme-primary)",
                className: "cursor-pointer",
              }}
              isBlurred={!userDataSended}
            />
            {userDataSended ? (
              <ButtonDS
                label={translate("rastreator.form.button.label")}
                onClick={() => navigateToQuestions()}
                className="mt-[16px] w-full hidden md:block"
              />
            ) : null}
          </div>
          <div className="boxInformationContainer__form" id="formC">
            {arrayForm && (
              <FormCustom
                arrayForms={arrayForm(translate)}
                buttonConfig={buttonForm(translate)}
                configForm="boxInformationContainer__form--formC"
                errorMessage={translate("rastreator.form.error.global")}
                formTitle={translate("rastreator.form.title")}
                initialValues={initialValues}
                submitForm={sendData}
                hideButton={userDataSended}
              />
            )}
          </div>
        </div>
      </div>
      {userDataSended ? (
        <div className="md:hidden fixed bottom-0 w-full bg-white py-[16px] px-[24px] z-50 border border-white">
          <ButtonDS
            label={translate("rastreator.form.button.label")}
            onClick={() => navigateToQuestions()}
            className="w-full"
          />
        </div>
      ) : null}
      <div className="infoContainer">
        <InformationComponent
          title={translate("rastreator.infoComponent.title")}
          informationItems={infoItems(translate)}
          customStyles={{ titleVariant: "Body" }}
        />
      </div>
      <div className="tableContainer">
        <hr></hr>
        <div className="tableContainer__table">
          <TableInfo
            title={translate("rastreator.table.title")}
            data={dataTable(translate)}
            columns={headerTable(translate) as any}
          />
        </div>
        <div className="tableContainer__CTA">
          <CTA
            title2CTA={translate("newUpsell.ctaSection.title2CTA")}
            titleCTA={translate("newUpsell.ctaSection.titleCTA")}
            paragraph1CTA={translate("newUpsell.ctaSection.paragraph")}
            paragraph2CTA=""
            textButton={translate("newUpsell.ctaSection.callUsButton").replace(
              "$",
              process.env.REACT_APP_TEL || ""
            )}
            phoneNumber={process.env.REACT_APP_TEL || ""}
            classNameCTA={"!p-0"}
          />
        </div>
      </div>
      <div className="trustpilotContainer">
        <RenderTrustpilot
          comments={Truspilot(translate)}
          excelentText={translate("newUpsell.truspilotSection.excelentText")}
          rating={4.8}
          title={translate("newUpsell.truspilotSection.title")}
          trustpilotText={translate(
            "newUpsell.truspilotSection.trustpilotText"
          )}
        />
      </div>
      <div className="faqContainer">
        <Faq
          headerProps={{
            title: "Preguntas frecuentes",
          }}
          accordion={faqAccordionData(translate)}
          globalRightIconProps={{
            displayedItemIcon: "PlusIcon",
            closedItemIcon: "MinusIcon",
            color: "var(--theme-primary)",
            className: "cursor-pointer",
          }}
        />
      </div>
    </div>
  );
};

export default RastreatorContainer;
