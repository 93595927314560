import { ButtonDS, ModalDS, RadioButton } from "get-life-storybook-ts";
import PhotoIDDS from "./PhotoIDDS";
import { useContext, useState } from "react";
import { BeneficiaryITContext } from "../../context/BeneficiaryIT.context";

const IdentificationModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    frontalDocument,
    backDocument,
    optionDocument,
    setFrontalDocument,
    setBackDocument,
    setOptionDocument,
    beneficiaryForm,
    updateIsDocumentEdited,
  } = useContext(BeneficiaryITContext);

  const [frontName, setFrontName] = useState("");
  const [backName, setBackName] = useState("");

  const translations = {
    steps: "Fare clic o trascinare qui una foto della facciata.",
    validations:
      "Assicuratevi che sia chiaro e leggibile.<br/>Formati: .jpg, .png, .pdf",
    uploading: "{{loaded}} MB di {{total}} MB ·",
    loading: "Caricamento...",
    completed: "Completato",
    deleteConfirmation: "È sicuro di voler eliminare l'ID?",
    cancel: "Annullamento",
    delete: "Cancellare",
    errorFormat: "Formato non valido.",
    errorSize: "L'immagine deve avere una dimensione inferiore a 10 MB.",
  };

  const isRadioDisabled = !!(frontalDocument || backDocument);

  return (
    <ModalDS
      open={open}
      onClose={onClose}
      title="Caricamento dell'identificazione"
      icon="UploadIcon"
      width={768}
      content={
        <div>
          <div className="grid md:grid-cols-2 gap-x-[16px] gap-y-[8px] pb-[16px] border-b border-b-[#E2E2E2]">
            <div className="flex flex-col">
              <span className="BodyM font-medium text-[#7B7B7B]">
                Dati del beneficiario
              </span>
              <span className="BodyM font-medium text-[#424242]">
                {(() => {
                  if (beneficiaryForm.name && beneficiaryForm.lastName)
                    return `${beneficiaryForm.name} ${beneficiaryForm.lastName}`;
                  if (beneficiaryForm.name) return `${beneficiaryForm.name}`;
                  return "";
                })()}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="BodyM font-medium text-[#7B7B7B]">Email</span>
              <span className="BodyM font-medium text-[#424242]">
                {beneficiaryForm.email}
              </span>
            </div>
          </div>
          <div className="py-[16px] flex flex-col gap-[16px] border-b border-b-[#E2E2E2]">
            <p className="BodyM font-medium text-[var(--dark-primary-color)]">
              Selezionate il tipo di documento d'identità da caricare. Ricordate
              che deve appartenere al suo beneficiario
            </p>
            <div className="grid grid-cols-[repeat(auto-fit,_170px)] gap-[16px] px-[16px]">
              <RadioButton
                checked={optionDocument === "ID"}
                value={"ID"}
                onChange={() => {
                  setOptionDocument("ID");
                }}
                label={"ID"}
                variant="SimpleRadio"
                disabled={isRadioDisabled}
              />
              <RadioButton
                checked={optionDocument === "RP"}
                value={"RP"}
                onChange={() => {
                  setOptionDocument("RP");
                }}
                label={"Patente di guida"}
                variant="SimpleRadio"
                disabled={isRadioDisabled}
              />
              <RadioButton
                checked={optionDocument === "PA"}
                value={"PA"}
                onChange={() => {
                  setOptionDocument("PA");
                }}
                label={"Passaporto"}
                variant="SimpleRadio"
                disabled={isRadioDisabled}
              />
            </div>
          </div>
          {optionDocument ? (
            <div className="pt-[16px]">
              <p className="BodyM font-medium text-[var(--dark-primary-color)]">
                Identificazione del beneficiario
              </p>
              <div className="grid grid-cols-2 gap-[32px] mt-[8px]">
                <PhotoIDDS
                  image={frontalDocument}
                  setImage={setFrontalDocument}
                  onLoadCallback={async () => updateIsDocumentEdited("FRONT")}
                  deleteFile={async () => setFrontalDocument("")}
                  translations={translations}
                  name={frontName}
                  setName={setFrontName}
                />
                {optionDocument !== "PA" ? (
                  <PhotoIDDS
                    image={backDocument}
                    setImage={setBackDocument}
                    onLoadCallback={async () => updateIsDocumentEdited("BACK")}
                    deleteFile={async () => setBackDocument("")}
                    translations={translations}
                    name={backName}
                    setName={setBackName}
                  />
                ) : null}
              </div>
              <p className="pt-[24px] BodyM text-[#7B7B7B]">
                Se si allega un pdf con entrambe le facciate del documento sulla
                stessa pagina, allegare lo stesso pdf in entrambi i casi.
                <br />
                Il nostro team operativo esaminerà questa documentazione prima
                di completare il processo di sottoscrizione.
              </p>
            </div>
          ) : null}
        </div>
      }
      buttons={
        <ButtonDS
          label="Continuare"
          className="w-[259px] ml-auto"
          onClick={onClose}
        />
      }
      buttonsWrap={true}
    />
  );
};
export default IdentificationModal;
