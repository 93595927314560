import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";
import { useEffect, useRef, useState } from "react";
import { ButtonDS, GlobalIcon, IconButton } from "get-life-storybook-ts";

interface PhotoIDDSI {
  image: string;
  setImage: (image: string) => void;
  onLoadCallback: (base64: string) => Promise<void>;
  deleteFile: () => Promise<void>;
  error?: boolean;
  isRemovable?: boolean;
  translations: PhotoIDDSTranslationsT;
  name: string;
  setName: (name: string) => void;
}

export type PhotoIDDSTranslationsT = {
  steps: string;
  validations: string;
  uploading: string;
  loading: string;
  completed: string;
  cancel: string;
  delete: string;
  deleteConfirmation: string;
  errorFormat: string;
  errorSize: string;
};

const PhotoIDDS = ({
  image,
  setImage,
  onLoadCallback,
  deleteFile,
  error = false,
  isRemovable = true,
  translations,
  name,
  setName,
}: PhotoIDDSI) => {
  const [uploadingImage, setUploadingImage] = useState(false);
  // Progress in MB
  const [uploadingProgress, setUploadingProgress] = useState({
    total: "0",
    loaded: "0",
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileError, setFileError] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const bytesToMegabytes = (bytes: number) => (bytes / 1000000).toFixed(2);

  const preventDefaults = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const highlightFileInput = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.style.backgroundColor = "#e6f3f0";
    target.style.borderColor = "#018565";
  };

  const unhighlightFileInput = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.style.backgroundColor = "";
    target.style.borderColor = "";
  };

  const handleOnDropImage = (e: React.DragEvent) => {
    let dt = e.dataTransfer;
    let files = dt.files;
    handleFile(files);
  };

  const triggerInputFile = () => fileInputRef.current?.click();

  const handleFile = (files: FileList) => {
    setFileError("");
    if (files.length >= 1) {
      const imageTypes = ["image/png", "image/jpeg", "application/pdf"];
      const file = files[0];
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (!imageTypes.includes(file.type)) {
        setFileError(translations.errorFormat);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setFileError(translations.errorSize);
        return;
      }
      if (file.type === "application/pdf") {
        setName(file.name);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = (e) => {
          setUploadingImage(true);
          setUploadingProgress({
            ...uploadingProgress,
            total: bytesToMegabytes(e.total),
          });
        };
        reader.onprogress = (e) => {
          setUploadingProgress({
            loaded: bytesToMegabytes(e.loaded),
            total: bytesToMegabytes(e.total),
          });
        };
        reader.onload = async () => {
          await onLoadCallback(reader.result as string);
          setUploadingImage(false);
          setImage(reader.result as string);
        };
        return;
      }
      let img = new Image();
      const imgUrl = URL.createObjectURL(file);
      img.src = imgUrl;
      setName(file.name);
      img.onload = () => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = (e) => {
          setUploadingImage(true);
          setUploadingProgress({
            ...uploadingProgress,
            total: bytesToMegabytes(e.total),
          });
        };
        reader.onprogress = (e) => {
          setUploadingProgress({
            loaded: bytesToMegabytes(e.loaded),
            total: bytesToMegabytes(e.total),
          });
        };
        reader.onload = async () => {
          await onLoadCallback(reader.result as string);
          setUploadingImage(false);
          setImage(reader.result as string);
        };
      };
    }
  };

  const handleFileInput = () => {
    if (fileInputRef.current?.files) {
      handleFile(fileInputRef.current?.files);
    }
  };

  const getFileName = () => {
    if (name) return name;
    const imageNameSplit = image.split("/");
    if (imageNameSplit.length > 0) {
      return imageNameSplit[imageNameSplit.length - 1];
    }
    return "";
  };

  useEffect(() => {
    setUploadingImage(false);
    setIsDeleting(false);
  }, [image]);

  if (image) {
    return (
      <>
        {isDeleting ? (
          <div className="border border-[#D9D8FD] bg-[#ECF0FF] p-[16px] min-h-[116px] rounded-[8px] flex flex-col items-start gap-[12px]">
            <div className="flex flex-row gap-[8px] items-center">
              <FileIcon />
              <div className="flex flex-col gap-[4px] flex-1">
                <p className="BodyL text-[#424242] font-bold">
                  {translations.deleteConfirmation}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-[16px] w-full">
              <ButtonDS
                label={translations.cancel}
                buttonType="secondary"
                size="32"
                className="flex-1"
                onClick={(e) => {
                  (e.target as HTMLElement).blur();
                  setIsDeleting(false);
                }}
              />
              <ButtonDS
                label={translations.delete}
                buttonType="tertiary"
                size="32"
                className="flex-1"
                onClick={deleteFile}
              />
            </div>
          </div>
        ) : (
          <div
            className="border border-[#D9D9D9] bg-[#ffffff] p-[16px] min-h-[116px] rounded-[8px] flex flex-col justify-center gap-[12px]"
            style={{
              backgroundColor: error ? "#FCEBEB" : undefined,
              borderColor: error ? "#E84B4B" : undefined,
            }}
          >
            <div className="flex flex-row gap-[8px] w-full items-center">
              {error ? <FileXIcon /> : <FileIcon />}
              <div className="flex flex-col gap-[4px] flex-1 min-w-0">
                <p className="BodyL text-[#424242] font-bold text-ellipsis overflow-hidden max-w-full">
                  {getFileName()}
                </p>
                {uploadingProgress.total !== "0" ? (
                  <div className="flex flex-row flex-wrap items-center gap-[4px]">
                    <span
                      className="text-[#7B7B7B] BodyS"
                      dangerouslySetInnerHTML={{
                        __html: translations.uploading
                          .replace("{{loaded}}", uploadingProgress.loaded)
                          .replace("{{total}}", uploadingProgress.total),
                      }}
                    />
                    <span className="text-[#424242] BodyS">
                      {translations.completed}
                    </span>
                  </div>
                ) : undefined}
              </div>
              <IconButton
                icon="TrashIcon"
                buttonType="secondary"
                size="32"
                onClick={(e) => {
                  (e.target as HTMLElement).blur();
                  setIsDeleting(true);
                }}
                disabled={!isRemovable}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  if (uploadingImage) {
    return (
      <div className="border border-[#D9D9D9] bg-[#ffffff] p-[16px] min-h-[116px] rounded-[8px] flex flex-col justify-center">
        <div className="flex flex-row gap-[8px]">
          <FileIcon />
          <div className="flex flex-col gap-[4px] flex-1 min-w-0">
            <p className="BodyL text-[#424242] font-bold text-ellipsis overflow-hidden max-w-full">
              {getFileName()}
            </p>
            {uploadingProgress.total !== "0" ? (
              <div className="flex flex-row items-center gap-[4px] flex-wrap">
                <span
                  className="text-[#7B7B7B] BodyS"
                  dangerouslySetInnerHTML={{
                    __html: translations.uploading
                      .replace("{{loaded}}", uploadingProgress.loaded)
                      .replace("{{total}}", uploadingProgress.total),
                  }}
                />
                <Spinner width={"18px"} height={"18px"} />
                <span className="text-[#424242] BodyS">
                  {translations.loading}
                </span>
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <input
        type="file"
        id="logoFileInput"
        accept="image/jpeg, image/png, application/pdf"
        onChange={handleFileInput}
        className="hidden"
        ref={fileInputRef}
      />

      <div
        className="text-[#424242] flex flex-col gap-[4px] p-[16px] border-[2px] border-dashed border-[var(--primary-color)] bg-[var(--light-primary-color)] rounded-[8px] cursor-pointer [&>*]:pointer-events-none"
        onDragEnter={(e) => {
          preventDefaults(e);
          highlightFileInput(e);
        }}
        onDragLeave={(e) => {
          preventDefaults(e);
          unhighlightFileInput(e);
        }}
        onDragOver={(e) => {
          highlightFileInput(e);
          preventDefaults(e);
        }}
        onDrop={(e) => {
          preventDefaults(e);
          unhighlightFileInput(e);
          handleOnDropImage(e);
        }}
        onClick={triggerInputFile}
      >
        <p
          className="BodyL font-bold"
          dangerouslySetInnerHTML={{ __html: translations.steps }}
        />
        <p
          className="BodyM font-normal"
          dangerouslySetInnerHTML={{ __html: translations.validations }}
        />
      </div>
      {fileError ? (
        <div className="text-[#EA5F5F] BodyS flex flex-row items-center gap-[8px] mt-[16px]">
          <GlobalIcon
            iconName="AlertCircleIcon"
            color="currentColor"
            size="XXS"
          />
          <span>{fileError}</span>
        </div>
      ) : undefined}
    </div>
  );
};

const FileIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3334 5.51636V12.183C23.3334 13.1035 24.0796 13.8497 25 13.8497H31.6667"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.3334 35.5164H11.6667C9.82576 35.5164 8.33337 34.024 8.33337 32.183V8.84969C8.33337 7.00874 9.82576 5.51636 11.6667 5.51636H23.3334L31.6667 13.8497V32.183C31.6667 34.024 30.1743 35.5164 28.3334 35.5164Z"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 15.5164H16.6667"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 22.1829H25"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 28.8499H25"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const FileXIcon = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4027 5V11.6667C23.4027 12.5871 24.1489 13.3333 25.0694 13.3333H31.736"
      stroke="#E53737"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.4027 35H11.736C9.89509 35 8.40271 33.5076 8.40271 31.6667V8.33333C8.40271 6.49238 9.89509 5 11.736 5H23.4027L31.736 13.3333V31.6667C31.736 33.5076 30.2437 35 28.4027 35Z"
      stroke="#E53737"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4431 19.2929C17.0525 18.9024 16.4194 18.9024 16.0289 19.2929C15.6383 19.6834 15.6383 20.3166 16.0289 20.7071L17.4431 19.2929ZM22.6955 27.3738C23.086 27.7643 23.7192 27.7643 24.1097 27.3738C24.5003 26.9832 24.5003 26.3501 24.1097 25.9596L22.6955 27.3738ZM24.1097 20.7071C24.5003 20.3166 24.5003 19.6834 24.1097 19.2929C23.7192 18.9024 23.086 18.9024 22.6955 19.2929L24.1097 20.7071ZM16.0289 25.9596C15.6383 26.3501 15.6383 26.9832 16.0289 27.3738C16.4194 27.7643 17.0525 27.7643 17.4431 27.3738L16.0289 25.9596ZM16.0289 20.7071L22.6955 27.3738L24.1097 25.9596L17.4431 19.2929L16.0289 20.7071ZM22.6955 19.2929L16.0289 25.9596L17.4431 27.3738L24.1097 20.7071L22.6955 19.2929Z"
      fill="#E53737"
    />
  </svg>
);

export default PhotoIDDS;
